import React from "react"
import News from "../../blocks/news"
import { Link } from "gatsby"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "maksimov_vs_fifa_in_cas" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>9 Декабря 2019, юристы Catenaccio представляли форварда <Link to={ `/players/maksimov` }>Максима Максимова</Link>, который подал апелляционный иск в Спортивный Арбитражный Суд в Лозанне против ФИФА на основе того, что Палата по Разрешению Споров ФИФА с нарушением закона истолковала трудовое соглашение в пользу работадателя футболиста. Этот спор привлёк большое внимание прессы.</p>
          <p><a href="https://lt.sputniknews.ru/sport/20190529/9169745/FIFA-diskvalifitsirovala-igravshego-v-Litve-rossiyanina.html">ФИФА дисквалифицировала игравшего в Литве россиянина</a></p>
          <p><a href="https://www.sportsdaily.ru/news/rossijskij-napadayushhij-vyigral-spor-u-fifa-blagodarya-svoemu-agentstvu">Российский нападающий выиграл спор у ФИФА благодаря своему агентству</a></p>
          <p><a href="https://m.sportsdaily.ru/articles/rossijskij-futbolist-pobedil-fifa-yurist-nazvavshij-ego-perexod-kamennym-vekom-ne-soglasen">Российский футболист победил ФИФА? Юрист, назвавший его переход «каменным веком», не согласен!</a></p>
        </div>
      </div>
    )
  }
}